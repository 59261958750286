// .repeat-bg {
// 	background-color: #f1f1f1;
// }

// .card-slider-wrapper {
// 	position: relative;
// 	max-height: calc(100vh - 84px);
// 	overflow: hidden;
// 	scroll-behavior: smooth;
// 	-webkit-transition: all 350ms ease-in-out;
// 	-moz-transition: all 350ms ease-in-out;
// 	-o-transition: all 350ms ease-in-out;
// 	transition: all 350ms ease-in-out;
// 	-webkit-transition-timing-function: ease-in-out;
// 	-moz-transition-timing-function: ease-in-out;
// 	-o-transition-timing-function: ease-in-out;
// 	transition-timing-function: ease-in-out;
// 	div.scroller-block {
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		.card {
// 			width: 500px;
// 		}
// 	}
// 	div.scroller {
// 		scroll-behavior: smooth;
// 		-webkit-transition: all 350ms ease-in-out;
// 		-moz-transition: all 350ms ease-in-out;
// 		-o-transition: all 350ms ease-in-out;
// 		transition: all 350ms ease-in-out;
// 		-webkit-transition-timing-function: ease-in-out;
// 		-moz-transition-timing-function: ease-in-out;
// 		-o-transition-timing-function: ease-in-out;
// 		transition-timing-function: ease-in-out;
// 		overflow: auto;
// 		z-index: 1;
// 		height: 100%;
// 		> div {
// 			text-align: center;
// 			height: calc(100vh - 84px);
// 			width: 100vw;
// 		}
// 	}
// }
.green {
	background: green !important;
}

.repeat-bg {
	background-color: #f1f1f1;
}


.card-slider-wrapper {
	position: relative;
	max-height: calc(100vh - 56px);
	overflow: hidden;
	width: 100vw;

	div.scroller-block {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;

		.card.container {
			overflow: auto;
			display: flex;
			flex-direction: column;
			min-height: 70vh;

			>.card-content {
				margin: auto;
				width: 100%;
			}

			>.card-action {
				margin: 0;
			}
		}

		@media only screen and (max-width: 840px) {
			.card {
				width: 100% !important;

				.col.m6 {
					width: 100%;
				}
			}
		}

		.card {
			// width: 50vw;
			font-size: 18px;

			.card-content {
				padding-top: 0 !important;
			}

			.card {
				width: 100%;


				.price {
					font-size: 2rem !important;
				}
			}
		}
	}


	div.scroller {
		overflow: auto;
		max-height: calc(100vh - 56px);
		z-index: 1;
		height: 100vh;

		>div {
			text-align: center;
			height: calc(100vh - 56px);
			width: 100vw;
		}

		@media only screen and (min-width: 601px) {
			>div {
				height: calc(100vh - 65px);
			}
		}
	}

	@media only screen and (min-width: 601px) {
		div.scroller {
			max-height: calc(100vh - 65px);
		}
	}
}

@media only screen and (min-width: 601px) {
	.card-slider-wrapper {
		max-height: calc(100vh - 65px);
	}
}

.compact {
	max-width: 100%;
	// table-layout: fixed;

	* {
		// max-width: 100%
	}

	tr td:first-of-type {
		width: 20%
	}
}

.compact td {
	padding: 0 5px;

}

.compact .notes-wrapper {
	line-height: 27px;
	max-height: 27px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.clean tr {

	border: 0
}

.rdrCalendarWrapper {
	max-width: 100%;

	.rdrMonth {
		padding: 0;
		max-width: 100%;
	}
}

.disableWeekends {
	select {
		display: unset;
	}

	.disabled,
	.rdrDayWeekend {
		background-color: rgb(248, 248, 248);
	}
}