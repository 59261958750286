$red: #ed1b24;

body {
	.home {
		nav {
		}
		.btn,
		.btn-large,
		.btn-small {
			text-decoration: none;
			color: #fff;
			background-color: $red;
			text-align: center;
			letter-spacing: 0.5px;
			-webkit-transition: background-color 0.2s ease-out;
			transition: background-color 0.2s ease-out;
			cursor: pointer;
		}

		.btn-small {
			height: 30px;
			line-height: 30px;
			font-size: 13px;
		}
	}
}

.btn-row {
	.btn:first-child {
		margin-left: 0 !important;
	}

	.btn:last-child {
		margin-right: 0 !important;
	}
	@media only screen and (max-width: 600px) {
		.btn {
			font-size: 0.8em !important;
			padding: 0 8px !important;
			height: 30px !important;
		}
	}
}

.ql-tooltip.ql-editing {
	z-index: 999;
}

.privacy-policy h1 {
	font-size: 2rem;
}

.privacy-policy h2 {
	font-size: 1.5rem;
}

.privacy-policy h3 {
	font-size: 1rem;
}

.privacy-policy p {
	font-size: 0.8rem;
}
