/* user list css */
/*--------------*/
.users-list-wrapper i {
	vertical-align: middle;
}

.users-list-wrapper .users-list-filter .show-btn {
	padding-top: 43px !important;
}

.users-list-wrapper .users-list-table {
	/* overflow: hidden; */
}

.users-list-wrapper .users-list-table .dataTables_filter label input {
	width: auto;
	height: auto;
	margin-left: 0.5rem;
}

.users-list-wrapper .users-list-table .dataTables_length label select {
	display: inline-block;

	width: auto;
	height: auto;
}

.users-list-wrapper .users-list-table .dataTable {
	border-collapse: collapse;
}

.users-list-wrapper .users-list-table .dataTable th {
	width: auto !important;
	padding: 19px 15px;

	border-bottom: 1px solid #e0e0e0;
}

.users-list-wrapper .users-list-table .dataTable tbody td {
	padding: 0.8rem 0.8rem;
}

.users-list-wrapper .users-list-table .dataTables_paginate {
	/* Pagination button styling */
}

.users-list-wrapper .users-list-table .dataTables_paginate .paginate_button {
	margin-top: 0.25rem;
	padding: 0.25em 0.65em;
}

.users-list-wrapper .users-list-table .dataTables_paginate .paginate_button.current,
.users-list-wrapper .users-list-table .dataTables_paginate .paginate_button:hover {
	color: white !important;
	border: 1px solid #3f51b5;
	border-radius: 4px;
	background: #3f51b5;
	box-shadow: 0 0 8px 0 #3f51b5;
}

/* user view css*/
/*-------------*/
.users-view i {
	vertical-align: middle;
}

.users-view .media .avatar {
	margin-right: 0.6rem;
}

.users-view .media .users-view-name {
	font-size: 1.47rem;
}

.users-view .quick-action-btns a {
	margin-left: 1rem;
}

.users-view .users-view-timeline {
	padding: 2rem;
}

.users-view .users-view-timeline h6 span {
	font-size: 2rem;

	vertical-align: middle;
}

.users-view .striped td:first-child {
	/* dynamic width change of first td*/
	width: 140px;
}

/* user edit css*/
/*-------------*/
.users-edit i {
	vertical-align: middle;
}

.users-edit .tabs .tab a {
	text-overflow: clip;
}

.users-edit .tabs .tab a span {
	position: relative;
	top: 2px;
}

.users-edit .tabs .tab a.active {
	border-radius: 4px;
	background-color: #e8eaf6;
}

.users-edit .user-edit-btns a {
	margin-right: 1rem;
}

.users-edit form button[type='submit'] {
	margin-right: 1rem;
}

@media (max-width: 600px) {
	.users-view-timeline h6 {
		/* view timeline text center*/
		text-align: center;
	}
	.users-view .media {
		margin-bottom: 0.5rem;
	}
	.users-view .media .media-heading {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
	.users-view .media .media-heading .users-view-name {
		font-size: 1.2rem;
	}
	.users-view .media .media-heading .users-view-username {
		font-size: 0.8rem;
	}
}
