.login-box {
	position: relative;
	padding: 30px 0 80px;
	@-webkit-keyframes wave1 {
		0% {
			-webkit-transform: skewX(-55deg);
			transform: skewX(-55deg);
		}
		50% {
			-webkit-transform: skewX(-50deg);
			transform: skewX(-50deg);
		}
		100% {
			-webkit-transform: skewX(-55deg);
			transform: skewX(-55deg);
		}
	}
	@keyframes wave1 {
		0% {
			-webkit-transform: skewX(-55deg);
			transform: skewX(-55deg);
		}
		50% {
			-webkit-transform: skewX(-50deg);
			transform: skewX(-50deg);
		}
		100% {
			-webkit-transform: skewX(-55deg);
			transform: skewX(-55deg);
		}
	}
	@-webkit-keyframes wave2 {
		0% {
			-webkit-transform: skewX(-45deg);
			transform: skewX(-45deg);
		}
		50% {
			-webkit-transform: skewX(-40deg);
			transform: skewX(-40deg);
		}
		100% {
			-webkit-transform: skewX(-45deg);
			transform: skewX(-45deg);
		}
	}
	@keyframes wave2 {
		0% {
			-webkit-transform: skewX(-45deg);
			transform: skewX(-45deg);
		}
		50% {
			-webkit-transform: skewX(-40deg);
			transform: skewX(-40deg);
		}
		100% {
			-webkit-transform: skewX(-45deg);
			transform: skewX(-45deg);
		}
	}
	@-webkit-keyframes wave3 {
		0% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
		}
		50% {
			-webkit-transform: skewX(-60deg);
			transform: skewX(-60deg);
		}
		100% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
		}
	}
	@keyframes wave3 {
		0% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
		}
		50% {
			-webkit-transform: skewX(-60deg);
			transform: skewX(-60deg);
		}
		100% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
		}
	}
	@-webkit-keyframes wave4 {
		0% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
			border-radius: 0;
		}
		50% {
			-webkit-transform: skewX(-60deg) rotateX(50deg);
			transform: skewX(-60deg) rotateX(50deg);
			border-radius: 90%;
		}
		100% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
			border-radius: 0;
		}
	}
	@keyframes wave4 {
		0% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
			border-radius: 0;
		}
		50% {
			-webkit-transform: skewX(-60deg) rotateX(50deg);
			transform: skewX(-60deg) rotateX(50deg);
			border-radius: 90%;
		}
		100% {
			-webkit-transform: skewX(-65deg);
			transform: skewX(-65deg);
			border-radius: 0;
		}
	}

	.stripe {
		position: absolute;
		width: 500px;
		height: 500px;
	}

	.stripe1 {
		top: -300px;
		left: -300px;
		-webkit-transform: skewX(-55deg);
		transform: skewX(-55deg);
		background: rgba(233, 63, 0, 0.3);
		-webkit-animation: wave1 6s infinite;
		animation: wave1 6s infinite;
	}

	.stripe2 {
		top: -350px;
		left: -350px;
		-webkit-transform: skewX(-45deg);
		transform: skewX(-45deg);
		background: rgba(233, 63, 0, 0.2);
		-webkit-animation: wave2 6s infinite;
		animation: wave2 6s infinite;
	}

	.stripe3 {
		top: -356px;
		left: -200px;
		-webkit-transform: skewX(-65deg);
		transform: skewX(-65deg);
		background: rgba(233, 63, 0, 0.1);
		-webkit-animation: wave3 6s infinite;
		animation: wave3 6s infinite;
	}

	.stripe4 {
		top: -322px;
		left: -200px;
		-webkit-transform: skewX(-65deg);
		transform: skewX(-65deg);
		background: rgba(233, 63, 0, 0.1);
		-webkit-animation: wave3 6s infinite;
		animation: wave3 6s infinite;
	}

	.stripe5 {
		top: -380px;
		left: -200px;
		-webkit-transform: skewX(-65deg);
		transform: skewX(-65deg);
		background: rgba(233, 63, 0, 0.4);
		-webkit-animation: wave3 6s infinite;
		animation: wave3 6s infinite;
	}

	.stripe6 {
		top: -179px;
		left: -191px;
		-webkit-transform: skewX(-65deg);
		transform: skewX(-65deg);
		background: rgba(233, 63, 0, 0.08);
		-webkit-animation: wave4 6s infinite linear;
		animation: wave4 6s infinite linear;
	}

	.stripe7 {
		top: -316px;
		left: 51px;
		-webkit-transform: skewX(-55deg);
		transform: skewX(-55deg);
		background: rgba(233, 63, 0, 0.06);
		-webkit-animation: wave4 6s infinite linear;
		animation: wave4 6s infinite linear;
	}
	label {
		color: black;
	}
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 500px;
		margin: 0 auto;
		max-height: 80vh;
		height: 500px;
		overflow: hidden;
		position: relative;
		background: linear-gradient(135deg, #e1e1e1 0%, white 50%, #e1e1e1 100%);
		box-shadow: 0px 0px 39px -5px rgba(0, 0, 0, 0.34);
		// background: linear-gradient(135deg, rgba(254,254,254,1) 0%, rgba(209, 209, 209, 0.14) 30%, rgba(240,240,240,0.81) 60%, rgba(245,245,245,1) 100%)
		// background: #fff;
		overflow-y: hidden;
		overflow-x: hidden;
		transition: all 0.5s ease;
		border-bottom: 4px solid #e93f00;
	}

	.signup {
		position: absolute;
		top: 0%;
		left: 50%;
		-webkit-transform: translate(-50%, 0%);
		width: 65%;
		z-index: 5;
		-webkit-transition: all 0.3s ease;

		&.slide-up {
			top: 5%;
			-webkit-transform: translate(-50%, 0%);
			-webkit-transition: all 0.3s ease;
		}

		&.slide-up .form-holder,
		&.slide-up .submit-btn {
			opacity: 0;
			visibility: hidden;
		}

		&.slide-up .form-title {
			font-size: 1em;
			cursor: pointer;
		}

		&.slide-up .form-title span {
			margin-right: 5px;
			opacity: 1;
			visibility: visible;
			-webkit-transition: all 0.3s ease;
		}

		.form-title {
			font-size: 1.5em;
			text-align: center;

			span {
				color: rgba(0, 0, 0, 0.4);
				opacity: 0;
				visibility: hidden;
				-webkit-transition: all 0.3s ease;
			}
		}

		.form-holder {
			overflow: hidden;
			margin-top: 50px;
			opacity: 1;
			visibility: visible;
			-webkit-transition: all 0.3s ease;

			.input {
				margin-bottom: 0;
				outline: none;
				box-shadow: none;
				display: block;
				height: 35px;
				line-height: 35px;
				width: 100%;
				font-size: 12px;
				&::-webkit-input-placeholder {
					color: rgba(0, 0, 0, 0.4);
				}
			}
		}

		.submit-btn {
			display: block;
			width: 100%;
			cursor: pointer;
			opacity: 1;
			visibility: visible;
			-webkit-transition: all 0.3s ease;

			&:hover {
				transition: all 0.3s ease;
				background-color: rgba(0, 0, 0, 0.8);
			}
		}
	}

	.login {
		position: absolute;
		top: 20%;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 5;
		-webkit-transition: all 0.3s ease;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: -20px;
			-webkit-transform: translate(-50%, 0);
			background-color: #fff;
			width: 200%;
			height: 250px;
			border-radius: 50%;
			z-index: 4;
			-webkit-transition: all 0.3s ease;
			border-top: solid 1px rgba(233, 63, 0, 0.3);
		}

		.centerx {
			position: absolute;
			top: calc(50% - 10%);
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			width: 65%;
			z-index: 5;
			-webkit-transition: all 0.3s ease;

			.form-title {
				color: #000;
				font-size: 1.7em;
				text-align: center;

				span {
					color: rgba(0, 0, 0, 0.4);
					opacity: 0;
					visibility: hidden;
					-webkit-transition: all 0.3s ease;
				}
			}

			.form-holder {
				overflow: hidden;
				opacity: 1;
				visibility: visible;
				-webkit-transition: all 0.3s ease;

				.input {
					margin-bottom: 0;
					outline: none;
					box-shadow: none;
					display: block;
					height: 35px;
					line-height: 35px;
					width: 100%;
					font-size: 12px;

					&::-webkit-input-placeholder {
						color: rgba(0, 0, 0, 0.4);
					}
				}
			}

			.submit-btn {
				display: block;
				width: 100%;
				cursor: pointer;
				opacity: 1;
				visibility: visible;
				-webkit-transition: all 0.3s ease;

				&:hover {
					transition: all 0.3s ease;
					background-color: rgba(0, 0, 0, 0.8);
				}
			}
		}

		&.slide-up {
			top: 90%;
			-webkit-transition: all 0.3s ease;
		}

		&.slide-up .centerx {
			top: 10%;
			-webkit-transform: translate(-50%, 0%);
			-webkit-transition: all 0.3s ease;
		}

		&.slide-up .form-holder,
		&.slide-up .submit-btn {
			opacity: 0;
			visibility: hidden;
			-webkit-transition: all 0.3s ease;
		}

		&.slide-up .form-title {
			font-size: 1em;
			margin: 0;
			padding: 0;
			cursor: pointer;
			-webkit-transition: all 0.3s ease;
		}

		&.slide-up .form-title span {
			margin-right: 5px;
			opacity: 1;
			visibility: visible;
			-webkit-transition: all 0.3s ease;
		}
	}
}

/*----------------------------------------
    Login Page
------------------------------------------*/
.login-bg {
	background-image: url('./flat-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
}

#login-page {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;

	height: 100vh;

	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

#login-page .card-panel.border-radius-6.login-card {
	margin-left: 0 !important;
}

html,
body {
	height: 100%;
}
