form {
	textarea.materialize-textarea:disabled,
	textarea.materialize-textarea:disabled+label,
	input[type=number]:not(.browser-default)+label,
	input[type=number]:not(.browser-default):disabled+label,
	input[type=number]:not(.browser-default):disabled,
	input[type=text]:not(.browser-default)+label,
	input[type=text]:not(.browser-default):disabled+label,
	input[type=text]:not(.browser-default):disabled{
		color:rgba(0,0,0,.7);
	}
	select,
	select:disabled{
		font-size:16px;
	}
	select,
	select+label,
	select:disabled+label,
	select:disabled{
		color:rgba(0,0,0,.9);
		border-color:rgba(0,0,0,.3);
	}
}

.current-cans-list {
	border-top:0;
	border-bottom:0;
	li.collection-item:first-of-type{
		padding-top:0;
		border-top:0;
	}
	li.collection-item:last-of-type{
		padding-bottom:0;
		border-bottom:0;
	}
	
	li.collection-item{
		padding:5px 0;
		border-bottom:0;
	}
		select:first-of-type{
			// border-left: 1px solid lightgrey;
			padding-left:10px;
		}

		select:last-of-type{
			// border-right: 1px solid lightgrey;
			padding-left:10px;
		}

		select,
		input[type=number],
		input[type=text] {
			margin: 0;
			border-bottom: 1px solid lightgrey;
			border-top: 1px solid lightgrey;
			border-left: none;
			border-right: none;
			border-radius:0;
		}
		input[type=number],
		input[type=text] {
			height: 38px;
		}
		select {
			height: 40px;
		}
}

.code-display {
	display:flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;

	div.code {
		flex-direction: column;
    position: relative;
    min-width: 0px;
    padding: 0px;
    margin: 0 0 10px 0;
    border: 0px;
    vertical-align: top;
    width: auto;
		box-shadow: 0 0 1px rgba(0,0,0,.7);
    display: inline-block;
    text-align: center;
		letter-spacing: 4px;
		background:lightgrey;
		border:1px solid grey;
		padding: 16.5px 16px;
		div{
			font-family: Montserrat, sans-serif;
			font-weight: 400;
			font-size: 1rem;
			line-height: 1.4375em;
			color: rgb(23, 43, 77);
			box-sizing: border-box;
			cursor: text;
			display: inline-flex;
			-webkit-box-align: center;
			align-items: center;
			width: 100%;
			position: relative;
			border-radius: 16px;

			div{
				font-family: Montserrat, sans-serif;
				font-weight: 400;
				font-size: 1rem;
				line-height: 1.4375em;
				color: rgb(23, 43, 77);
				box-sizing: border-box;
				cursor: text;
				display: inline-flex;
				-webkit-box-align: center;
				align-items: center;
				width: 100%;
				position: relative;
				border-radius: 16px;
				font: inherit;
				letter-spacing: inherit;
				color: currentcolor;
				border: 0px;
				box-sizing: content-box;
				background: none;
				height: 1.4375em;
				margin: 0px;
				-webkit-tap-highlight-color: transparent;
				display: block;
				min-width: 0px;
				width: 100%;
				animation-name: mui-auto-fill-cancel;
				animation-duration: 10ms;
				border:1px solid grey;
			}
		}
	}
}