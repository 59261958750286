// @use './trash-joes';
// @use './atlantic-waste';

// .hauler-customer-signup {
// 	$primary-color: #6db262;
// 	$secondary-color: #fff;
// 	$primary-text-color: #fff;
// 	$secondary-text-color: #000;

// 	nav {
// 		background: $primary-color;
// 	}

// 	.collection .collection-item {
// 		background-color: $secondary-color;
// 		color: $secondary-text-color;
// 	}

// 	.collection .collection-item.active {
// 		background-color: $primary-color;
// 		color: $primary-text-color;
// 	}

// 	.btn,
// 	.btn-large,
// 	.btn-small {
// 		background-color: $primary-color;
// 	}
// }

.card {
	.card-title {
		color: white;
		padding: 10px;
		border-radius: 2px;
	}

	.themedButton {
		font-size: 15px;
		color: white;
		margin: 0 50px;
	}

	input[type='number'] {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	.number-input {
		border: 2px solid #ddd;
		display: inline-flex;
	}

	.number-input,
	.number-input * {
		box-sizing: border-box;
	}

	.number-input button {
		outline: none;
		-webkit-appearance: none;
		background-color: transparent;
		border: none;
		align-items: center;
		justify-content: center;
		width: 3rem;
		height: 3rem;
		cursor: pointer;
		margin: 0;
		position: relative;
	}

	.number-input button:before,
	.number-input button:after {
		display: inline-block;
		position: absolute;
		content: '';
		width: 1rem;
		height: 2px;
		background-color: #212121;
		transform: translate(-50%, -50%);
	}

	.number-input button.plus:after {
		transform: translate(-50%, -50%) rotate(90deg);
	}

	.number-input input[type='number'] {
		font-family: sans-serif;
		max-width: 5rem;
		padding: 0.5rem;
		border: solid #ddd;
		border-width: 0 2px;
		font-size: 2rem;
		height: 2rem;
		font-weight: bold;
		text-align: center;
		margin: 0;
	}

	.collection .collection-item.active {
		background-color: #000;
		color: white !important;
	}

	@media only screen and (max-width: 840px) {

		.card-action,
		.card-content {
			padding: 10px;
		}

		.themedButton {
			margin: 0 5px;
			font-size: 12px;
			padding: 0 10px;
		}

		.card .card-action {
			padding: 10px 12px;
		}
	}
}