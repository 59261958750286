.feedback-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: var(--background-color);
}

.feedback-page .review-box {
	background-color: white;
	border-radius: 10px;
	padding: 40px 20px;
	text-align: center;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	max-width: 95%;
	width: 450px;
}

.feedback-page img {
	max-width: 100%;
}

.feedback-page .title {
	font-size: 36px;
	font-weight: bold;
	color: #007bff;
	margin-bottom: 20px;
}

.feedback-page .subtitle {
	font-size: 16px;
	color: #333;
	margin-bottom: 20px;
}

.feedback-page .description {
	font-size: 14px;
	color: #666;
	margin-bottom: 20px;
}

.feedback-page .buttons {
	display: flex;
	justify-content: center;
	gap: 15px;
	flex-direction: column;
}

.feedback-page .button.google img,
.feedback-page .button.facebook img {
	height: 40px;
}

.feedback-page .button {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f0f0f0;
	border: 2px solid #aaa;
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 18px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.3s;
	flex-grow: 1;
}

.feedback-page .button img.icon {
	margin-right: 10px;
	width: 24px;
	height: 24px;
}

.feedback-page .button.yes {
	background-color: #007bff;
	color: white;
}

.feedback-page .button.yes:hover {
	background-color: #0056b3;
}

.feedback-page .button.no {
	background-color: #f0f0f0;
	color: #333;
}

.feedback-page .button.no:hover {
	background-color: #e0e0e0;
}

.feedback-page .button.google {
	/* background-color: #f0f0f0;
	color: #333; */
}

.feedback-page .button.google:hover {
	background-color: #e0e0e0;
}

.feedback-page .button.facebook {
	/* background-color: #3b5998;
	color: white; */
}

.feedback-page .button.facebook:hover {
	/* background-color: #2d4373; */
}

.feedback-page .button.submit {
	background-color: #dcdcdc;
	color: #333;
	margin-top: 20px;
	width: 100%;
}

.feedback-page .button.submit:hover {
	background-color: #c0c0c0;
}

.feedback-page .footer-text {
	font-size: 14px;
	color: #666;
	margin-top: 20px;
}

.feedback-page .footer-text a {
	color: #007bff;
	text-decoration: none;
}

.feedback-page .footer-text a:hover {
	text-decoration: underline;
}

.feedback-page .error {
	background-color: #f8d7da;
	color: #721c24;
	border: 1px solid #f5c6cb;
	padding: 15px;
	border-radius: 5px;
	font-family: Arial, sans-serif;
	margin: 20px 0;
}

.feedback-page .error p {
	margin: 0;
}

.feedback-page .footer {
	margin-top: 20px;
	font-size: 14px;
	color: white;
	font-weight: bold;
}

.feedback-page .textarea {
	width: 100%;
	height: 80px;
	padding: 10px;
	border: 1px solid #dcdcdc;
	border-radius: 5px;
	font-size: 14px;
	color: #333;
	resize: none;
}

.feedback-page .textarea::placeholder {
	color: #aaa;
}
