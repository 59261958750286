.home {
	html {
		font-family: 'Montserrat', sans-serif;
	}

	.cursive {
		font-family: 'aerostatregular', 'Reenie Beanie', cursive;
	}

	body .btn-large {
		font-size: 20px;
		font-weight: 700 !important;
		line-height: 50px;
		height: 50px;
		padding: 0 20px;
	}

	body .btn,
	body .btn-large,
	body .btn-small {
		text-decoration: none;
		color: #fff;
		background-color: #1bed2c;
		text-align: center;
		letter-spacing: 0.5px;
		transition: background-color 0.2s ease-out;
		cursor: pointer;
		box-shadow: none;
		border-radius: 0;
		font-weight: 500;
	}

	body .btn:hover,
	body .btn-large:hover,
	body .btn-small:hover {
		background-color: black !important;
	}

	.container {
		margin: 0 auto;
		max-width: 1200px;
		width: 95%;
	}

	.container h1 {
		font-weight: 700;
		font-size: 2.5rem;
	}

	.container h2 {
		font-size: 2.3rem;
		font-weight: 600;
	}

	.container p {
		font-size: 17px;
	}

	nav {
		height: 85px;
		line-height: 85px;
	}

	nav ul a {
		font-size: 1.2rem;
		padding: 0;
		margin: 0 15px;
		line-height: 40px;
		color: #ed1b24;
		font-weight: bold;
	}

	nav ul li:hover a {
		background-color: transparent;
		color: rgb(90, 90, 90);
	}
	.interior {
		margin-top: 20px;
		nav {
			height: 100px;
			line-height: 100px;
		}
		nav ul a {
			font-size: 1.2rem;
			padding: 0 15px;
			margin: 0;
			line-height: 40px;
			color: white;
			// font-weight: 300;
		}
		nav ul a:hover {
			color: #ed1b24;
			background-color: #fff;
		}
	}

	.hvr-underline-reveal::before {
		content: '';
		position: absolute;
		z-index: -1;
		left: 0;
		right: 0;
		bottom: 0;
		background: black;
		height: 6px;
		-webkit-transform: translateY(6px);
		transform: translateY(6px);
		-webkit-transition-property: transform;
		transition-property: transform;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	}

	.hvr-underline-reveal {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		position: relative;
		overflow: hidden;
	}

	.hvr-underline-reveal:hover::before,
	.hvr-underline-reveal:focus::before,
	.hvr-underline-reveal:active::before {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	.demoRibon {
		height: auto;
		display: flex;
		align-items: center;
		margin: 50px 0px 50px;
	}

	.container footer li {
		display: inline;
		margin: 20px;
	}

	.container footer li a {
		color: #fff;
		line-height: 40px;
	}

	.titleStyle h1 {
		margin: 0;
		padding: 0;
		font-weight: 400;
		color: #ed1b24;
		text-transform: uppercase;
		font-size: 2.5rem !important;
	}
	.whiteBluryBg {
		background-color: rgba(255, 255, 255, 0.8);
		padding: 20px;
		margin-top: 120px;
		margin-left: 30px !important;
		margin-bottom: 100px;
		box-shadow: rgba(255, 255, 255, 0.86) 0px 0px 60px 50px;
	}

	.modalCloseBtn {
		position: absolute;
		top: 1px;
		right: 1px;
		line-height: 40px;
		z-index: 10000;
		width: 40px;
		height: 40px;
		text-align: center;
		background-color: white;
	}

	.modal-close {
		font-size: 30px;
		color: #ed1b24;
	}

	.sticky {
		position: -webkit-sticky;
		position: sticky;
		top: 100px;
		padding: 0 10px 10px 0;
		float: left;
		width: 70px;
		margin-top: 20px;
		text-align: center;
	}

	.ssIcon a {
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		color: rgba(0, 0, 0, 0.7);
		width: 45px;
		height: 45px;
		vertical-align: middle;
		line-height: 41px;
		border-radius: 50%;
		font-size: 25px;
		margin: 5px;
		text-align: center;
		border: 2px solid;
		display: inline-block;
	}

	.fbIcon:hover {
		background-color: #3a5794;
		border: 2px solid #3a5794;
		color: white;
	}

	.twIcon:hover {
		background-color: #1c9cea;
		border: 2px solid #1c9cea;
		color: white;
	}

	.igIcon:hover {
		background-color: #da2f69;
		border: 2px solid #da2f69;
		color: white;
	}

	.eIcon:hover {
		background-color: #85d80a;
		border: 2px solid #85d80a;
		color: white;
	}

	.discStyle li {
		list-style-type: disc;
		font-size: 17px;
		margin: 10px 0 10px 40px;
	}
	ol li {
		margin: 10px 0;
		font-size: 17px;
	}

	.lineThoBox {
		position: relative;
		overflow: hidden;
	}

	.lineTho::after {
		content: '';
		position: absolute;
		top: 58%;
		width: 100%;
		height: 1px;
		background-color: #bdbdbd;
		margin-left: 20px;
	}

	h4 {
		font-size: 25px;
		font-weight: 600;
	}

	.card {
		margin: 0.5rem 5px 20px 5px;
	}

	.replyCommentPost,
	.commentPost {
		border-radius: 20px;
	}

	.replys {
		margin-top: -40px;
		margin-left: 45px;

		.replyCommentPost:last-of-type {
			border-radius: 0 0 20px 20px;
			margin-bottom: 20px;
		}

		.replyCommentPost:first-of-type {
			border-radius: 20px 0 0 0;
		}

		.replyCommentPost {
			margin-bottom: 0;
			margin-top: 0;
			border-radius: 0 0 0 0;
		}
	}

	.leaveComment {
		background-color: #f7f7f7;
	}

	.displayAdj {
		display: block !important;
		align-items: center !important;
	}

	.padAdj {
		padding: 0 !important;
	}

	.alignAdj {
		text-align: center;
	}

	.hero-text {
		text-align: center;
		position: absolute;
		top: 50%;
		left: auto;
		transform: translate(0%, -50%);
		padding: 0px 20px;
	}

	.logoPoHome {
		position: absolute;
		top: 20px;
		left: 0;
		right: auto;
		margin: auto;
		width: 200px;
	}

	nav .sidenav-trigger i {
		color: red;
		font-size: 45px;
	}

	.sidenav li > a {
		font-weight: bold;
		color: red;
		font-size: 17px;
		display: block;
	}

	.logoPoInter {
		width: 250px;
		margin: 30px 0 0 80px;
	}
	.bgWhy {
		background-image: url('/static/media/TJ-whyjoe-hero.23eeae4f.jpg');
		background-size: cover;
		background-position: bottom center;
		background-repeat: no-repeat;
	}
	.right-align-adj {
		text-align: center;
	}
	.left-align-adj {
		text-align: center;
	}

	/* Tablet style m-size */
	@media only screen and (min-width: 601px) {
		// .displayAdj {
		// 	display: flex !important;
		// 	align-items: center !important;
		// }

		.padAdj {
			padding: 0 10px !important;
		}

		// .demoRibon {
		// 	height: 370px;
		// 	// height: 300px;
		// 	margin: 100px 0px 50px;
		// }

		.alignAdj {
			text-align: right;
		}

		.hero-text {
			left: 50%;
		}

		.container h1 {
			font-size: 3.3rem;
		}
		.right-align-adj {
			text-align: right;
		}
		.left-align-adj {
			text-align: left;
		}
	}
	.mac-displaced {
		right: 0;
	}
	/* Desktop style l-size*/
	@media only screen and (max-width: 993px) {
		.demoRibon img {
			max-width: 400px !important;
		}
		.mac-displaced {
			text-align: center;
			width: 100%;
		}

		.demoRibbon .right-align p {
			text-align: center !important;
		}
	}
	@media only screen and (min-width: 993px) {
		.displayAdj {
			display: flex !important;
			align-items: center !important;
		}

		.padAdj {
			padding: 0 30px !important;
		}
		.demoRibon {
			height: 370px;
			margin: 100px 0px 50px;
		}

		.alignAdj {
			text-align: right;
		}

		.hero-text {
			left: 50%;
		}

		.container h1 {
			font-size: 3.3rem;
		}

		.logoPoHome {
			top: 30px;
			left: 50px;
			right: auto;
			margin: auto;
			width: 330px;
		}
		.right-align-adj {
			text-align: right;
		}
		.left-align-adj {
			text-align: left;
		}
	}
}

.wrap-contact.displayAdj h4 {
	margin-top: 10px;
}

.home img.card {
	margin: 0 !important;
}

@media only screen and (max-width: 840px) {
	#blog-list {
		text-align: center;
	}
	.home .home-blog-intro p {
		text-align: center;
	}
	.home .home-blog-intro {
		padding: 40px 0px !important;
	}
	#tokenFrame {
		width: 100%;
	}

	.card-title.m0 {
		margin: 0;
	}
	.found {
		#map{
			display: none;
		}
	}
	#map {
		width: 100%;
		max-height: 200px;
	}

	#pano {
		width: 100%;
		max-height: 200px;
	}

	.repeat-bg .card-title {
		font-size: 15px;
		white-space: nowrap;
	}

	.card-slider-wrapper div.scroller-block .card {
		font-size: 14px !important;
	}

	// .repeat-bg div.card.container .card-content {
	// 	flex-grow: 10;
	// }

	// .repeat-bg div.card.container .card-action {
	// 	flex-grow: 1;
	// }

	// .repeat-bg div.card.container {
	// 	max-height: 85vh;
	// 	display: flex;
	// 	flex-direction: column;
	// }

	.card .card-title {
		padding: 2px 10px !important;
	}

	.card .card-content .card .card-content p {
		font-size: 14px !important;
	}

	.push-right {
		margin-left: auto;
		max-width: 50px !important;
	}

	.card .card-content .card .card-content .right .row::after {
		content: unset !important;
	}

	// .card .card-content .card .card-content .right .row button {
	// 	// flex-grow: 1;
	// 	max-width: 100px;
	// }

	// .card .card-content .card .card-content .right .row {
	// 	display: flex;
	// 	justify-content: space-between !important;
	// }

	// .card .card-content .card .card-content .right {
	// 	width: 100%;
	// 	float: none;
	// }
}
.home .home-blog-intro {
	padding: 40px;
}
